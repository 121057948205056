import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Front from "../components/front"
import About from "../components/about"
import Acts from "../components/acts"
import Contact from "../components/contact"

import "../styles/style.scss"
import "../styles/style.css"


const IndexPage = () => (
  <Layout>
    <SEO title="Services" />
    <About/>
    <div className="services">
      <h1 style={{ textAlign: "center" }}>Services</h1>
      <p>Though we used to handle all types of civil cases before the Hon'ble High court of Kerala our area of specialisation is matrimonial cases both before the Family Courts and at the High court level. Matrimonial litigation relates to remedies to problems in marriage like. (1)Declaration of a marriage as null and void due to certain invalidating circumstances that existed on or before the date of marriage.(2) Dissolution of marriage or divorce due to the reasons that have arisen after marriage. This can be by alleging and proving the grounds mentioned in law or filing a petition jointly (mutual consent divorce, Mubarath) without assigning any reason other than they find it impossible to live together as husband and wife and that there is no chance for a reunion.(3) Judicial separation for those who do not wish to live together due to legally permissible reasons but do not want a divorce. Grounds for this are almost same as in the case of divorce.(4) Restitution of conjugal rights to bring back a spouse living separate without legally justifiable reason</p>
      <p>The other matters that come within the purview of matrimonial litigations are declaration of validity of marriage.,custody and guardianship of children, maintenance, return of money and property, matrimonial appeals, review petition, revisionn petition, declaration and injunction relating to property, adoption of children, Quashing of criminal case based on settlement, transfer of pending cases from one court to another court etc.</p>
      <p>Though marriages in India are generally a matter of high religious significance except in the case of civil marriages under the Special Marriage Act,the role of religion ends with marriage and from then on civil laws will step in. From then onwards all rights and liabilities are regulated by civil laws To be specific though marriages as are solemnised according to customs and ceremonies prevalent in various communities by religious authorities they have no right to dissolve or annul a marriage.It has to be through court only .The divorce granted by church Tribunals or organisation like SNDP has no legal validity.</p>
    </div>
  </Layout>
)

export default IndexPage
